// REACT AND GATSBY
import React, { useState } from 'react';
// ELEMENTS AND COMPONENTS
import Box from 'reusecore/src/elements/Box';
import Button from 'reusecore/src/elements/Button';
import Container from 'common/src/components/UI/Container';
// import ErrorSec from 'gatsby-landing/src/containers/Error';
import Input from 'reusecore/src/elements/Input';
import PodcastTile from 'common/src/components/InfoTile/podcastTile';
// STYLING
import PodcastSearchWrapper, { Styles } from './podcastSearch.styles';
// LOGIC AND HANDLERS
import { InputHandler } from './handlers';
// NPM MODULES

const PodcastSearch = ({ heading, redirectPath, tileClickCB }) => {
  const [searchData, setSearchData] = useState({
    query: ' ',
    message: {
      prev_offset: -1,
      next_offset: 0,
      results: [],
    },
  });

  const items = [];
  const { row, podCol, buttonStyle } = Styles;
  const { prev_offset, next_offset } = searchData.message;

  if (searchData.message.results && searchData.message.results.length > 0) {
    searchData.message.results.map(podcast =>
      items.push(
        <Box 
          {...podCol}
          key={podcast.id}
        >
          <PodcastTile
            tileStyle='imageOnly'
            podcast={podcast}
            redirectObj={{
              path: redirectPath,
              queryParams: null,
              onImageClickCB: tileClickCB,
            }}
          />
        </Box>
      )
    );
  }

  // console.log(items)
  return (
    <PodcastSearchWrapper>
      <Container className="podcast_search_container">
        {/* ** TITLE BAR (optional) ** */}
        { heading ? 
        <Box {...row}>
          <h1>{heading}</h1>
        </Box>:<></>
        }

        {/*** SEARCH BAR ***/}
        <Box {...row}>
          <Input
            className="search_input"
            inputType="text"
            label="Find your podcast"
            size="50"
            isMaterial={true}
            onKeyDown={e =>
              InputHandler.handleEnterKey(e, async () => {
                setSearchData(
                  await InputHandler.loadPodcasts(searchData, false)
                );
              })
            }
            onChange={value =>
              setSearchData({ query: value, message: searchData.message })
            }
          />

          <Button
            {...buttonStyle}
            title="SEARCH"
            onClick={async () =>
              setSearchData(await InputHandler.loadPodcasts(searchData, false))
            }
          />
        </Box>

        {/*** SEARCH RESULTS META ***/}
        {items.length > 0 ? (
          <Box {...row}>
            <h4>{searchData.message.total + ' podcasts found'}</h4>
          </Box>
        ) : (
          <></>
        )}

        {/*** GEWGLE STYLE LOAD RESULTS ***/}
        <Box {...row}>{items}</Box>

        {/*** LOAD MORE RESULTS BUTTON ***/}
        {prev_offset != next_offset && items.length > 0 ? (
          <Box {...row}>
            <Button
              {...buttonStyle}
              title="LOAD MORE PODCASTS"
              onClick={async () =>
                setSearchData(await InputHandler.loadPodcasts(searchData, true))
              }
              // style={{backgroundColor: 'blue'}}
            />
          </Box>
        ) : (
          <></>
        )}
      </Container>
    </PodcastSearchWrapper>
  );
};

export default PodcastSearch;
