import styled from 'styled-components';

const PodcastSearchWrapper = styled.div`
  position: relative;
  /* background: red; */
  overflow: hidden;
  padding: 15px 0 40px 0;

  .podcast_search_container {
    background-repeat: no-repeat;
    background-position: center 50px;
    /* padding-top: 80px; */
    padding-bottom: 20px;
    position: relative;
  }

  .search_input {
    flex-grow: 1;
    margin-right: 20px;

    @media (max-width: 575px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
    &.is-material {
      &.is-focus {
        label {
          font-size: 14px;
          color: #fff;
        }
        .highlight {
          background: #fff;
          height: 1px;
        }
      }
    }

    .highlight {
      background: rgba(255, 255, 255, 0.4);
    }

    input {
      background: transparent;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      padding: 10px 15px;
      border-color: #dadada;
      @media (max-width: 575px) {
        height: 48px;
      }
    }

    label {
      font-size: 17px;
      color: #fff;
      font-weight: 400;
      padding-left: 10px;
      top: 5px;
      pointer-events: none;
    }
  }
`;

const Styles = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    mt: '10px',
    mb: '10px',
  },
  podCol: {
    width: [1 / 2, 1 / 3, 1 / 3, 1 / 4],
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '700',
    pl: '30px',
    pr: '30px',
    colors: 'secondaryWithBg',
    color: '#03103b',
  },
};

export { Styles };
export default PodcastSearchWrapper;
