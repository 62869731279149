/**
 * Handler for input events for PodcastSearch
 *
 * This is where we call our custom API to manage Job requests
 */

import { doFetch as podstacksAPI } from '../../../../api/PodstacksAPI';
import { handleEnterKey } from '../../../../api/BrowserAPI';

// Loads initial and subsequent podcast results
const loadPodcasts = async (searchData, next = true) => {
  let { query } = searchData;
  let message = null;
  if (next) {
    message = await getResults(query, searchData.message);
  } else {
    message = await getResults(query, {
      prev_offset: -1,
      next_offset: 0,
      results: [],
    });
  }
  // console.log(message)
  return { query, message };
};

// Get podcast search results
const getResults = async (query, prevResults) => {
  console.log({ query, prevResults });
  const { next_offset, prev_offset } = prevResults;

  // Perform query if there are more podcasts to fetch
  if (query !== ' ' && next_offset !== prev_offset) {
    console.log('Searching for podcasts: ' + query);

    // Simplified API fetch
    const newResults = await podstacksAPI(
      'podcasts',
      'searchPodcasts',
      null,
      {
        q: query,
        type: 'podcasts',
        offset: next_offset,
        language: 'English',
        safe_mode: 0,
      },
      null,
      null,
      null
    );

    // If new/first query, return without appending results
    if (next_offset == 0) {
      newResults.prev_offset = prevResults.next_offset;
      return await newResults;
    } else {
      // If subsequent query, return appended results
      prevResults.prev_offset = prevResults.next_offset;
      prevResults.next_offset = newResults.next_offset;
      newResults.results.map(async podcast => {
        await prevResults.results.push(podcast);
      });
      return await prevResults;
    }
  } else {
    return prevResults;
  }
};

export const InputHandler = {
  loadPodcasts,
  getResults,
  // redirectToPage,
  handleEnterKey,
};
